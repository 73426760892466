@import 'colors';

ngb-datepicker {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.08);

  .ngb-dp-navigation-chevron {
    border-width: 0.15rem 0.15rem 0 0;
    width: 0.5rem;
    height: 0.5rem;
  }

  .ngb-dp-header {
    background-color: $white;
    padding: 0.25rem;

    .ngb-dp-navigation-select {
      gap: 0.5rem;
    }
  }

  .ngb-dp-content {
    .ngb-dp-month:first-child .ngb-dp-week {
      padding-left: 0;
    }

    .ngb-dp-month:last-child .ngb-dp-week {
      padding-right: 0;
    }

    .ngb-dp-month .ngb-dp-week:last-child {
      padding-bottom: 0;
    }

    .ngb-dp-weekdays {
      border-bottom: none;
      border-radius: 0;
      background-color: $gray-200;

      .ngb-dp-weekday {
        color: $black;
        font-style: normal;
      }
    }
  }

  [ngbDatepickerDayView] {
    border-radius: 0;
    border: 0.0625rem solid $gray-200;
  }
}
