@font-face {
  font-family: 'nSpace-icons';
  src:  url('fonts/nSpace-icons.eot?d5ycmd');
  src:  url('fonts/nSpace-icons.eot?d5ycmd#iefix') format('embedded-opentype'),
    url('fonts/nSpace-icons.ttf?d5ycmd') format('truetype'),
    url('fonts/nSpace-icons.woff?d5ycmd') format('woff'),
    url('fonts/nSpace-icons.svg?d5ycmd#nSpace-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ni-"], [class*=" ni-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'nSpace-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ni-3d-box:before {
  content: "\e9c4";
}
.ni-delete-2:before {
  content: "\e9c5";
}
.ni-cancel_share:before {
  content: "\e9be";
}
.ni-menu:before {
  content: "\e9bf";
}
.ni-redo:before {
  content: "\e9c0";
}
.ni-share:before {
  content: "\e9c1";
}
.ni-undo:before {
  content: "\e9c2";
}
.ni-user:before {
  content: "\e9c3";
}
.ni-live-help:before {
  content: "\e9bd";
}
.ni-email:before {
  content: "\e9b3";
}
.ni-call:before {
  content: "\e9b4";
}
.ni-favorite-outline:before {
  content: "\e9b5";
}
.ni-favorite:before {
  content: "\e9b6";
}
.ni-pending-actions:before {
  content: "\e9b7";
}
.ni-document-important:before {
  content: "\e9b8";
}
.ni-request-quote:before {
  content: "\e9b9";
}
.ni-error-outline:before {
  content: "\e9ba";
}
.ni-widgets:before {
  content: "\e9bb";
}
.ni-widgets-fill:before {
  content: "\e9bc";
}
.ni-add-box:before {
  content: "\e97d";
}
.ni-add-to:before {
  content: "\e97e";
}
.ni-assignment-1:before {
  content: "\e97f";
}
.ni-assignment-turned-in:before {
  content: "\e980";
}
.ni-attachment:before {
  content: "\e981";
}
.ni-autorenew:before {
  content: "\e982";
}
.ni-cached:before {
  content: "\e983";
}
.ni-cancel-block:before {
  content: "\e984";
}
.ni-design:before {
  content: "\e985";
}
.ni-done-outline:before {
  content: "\e986";
}
.ni-file-download-outline:before {
  content: "\e987";
}
.ni-file-download:before {
  content: "\e988";
}
.ni-filter-list:before {
  content: "\e9b2";
}
.ni-flip:before {
  content: "\e989";
}
.ni-highlight:before {
  content: "\e98a";
}
.ni-history-toggle-off:before {
  content: "\e98b";
}
.ni-image:before {
  content: "\e98c";
}
.ni-insert-photo:before {
  content: "\e98d";
}
.ni-label:before {
  content: "\e98e";
}
.ni-link:before {
  content: "\e98f";
}
.ni-lock-outline:before {
  content: "\e990";
}
.ni-loop:before {
  content: "\e991";
}
.ni-merge:before {
  content: "\e992";
}
.ni-new-outline:before {
  content: "\e993";
}
.ni-new-rectangle:before {
  content: "\e994";
}
.ni-new-release:before {
  content: "\e995";
}
.ni-new:before {
  content: "\e996";
}
.ni-not-interested:before {
  content: "\e997";
}
.ni-privacy-check:before {
  content: "\e998";
}
.ni-privacy:before {
  content: "\e999";
}
.ni-published-with-changes:before {
  content: "\e99a";
}
.ni-question-answer:before {
  content: "\e99b";
}
.ni-settings-1:before {
  content: "\e99c";
}
.ni-split:before {
  content: "\e99d";
}
.ni-subdirectory-arrow-right:before {
  content: "\e99e";
}
.ni-swap-vert:before {
  content: "\e99f";
}
.ni-swap-vertical-circle:before {
  content: "\e9a0";
}
.ni-sync-alt:before {
  content: "\e9a1";
}
.ni-trash:before {
  content: "\e9a2";
}
.ni-verified:before {
  content: "\e9a3";
}
.ni-visibility-off:before {
  content: "\e9a4";
}
.ni-visibility:before {
  content: "\e9a5";
}
.ni-cancel-fill:before {
  content: "\e9a6";
}
.ni-checkbox-checked:before {
  content: "\e9a7";
}
.ni-checkbox-indeterminate:before {
  content: "\e9a8";
}
.ni-checkbox-unchecked:before {
  content: "\e9a9";
}
.ni-delete-1:before {
  content: "\e9aa";
}
.ni-lock:before {
  content: "\e9ab";
}
.ni-remove-circle-1:before {
  content: "\e9ac";
}
.ni-remove-circle-outline-1:before {
  content: "\e9ad";
}
.ni-report-problem:before {
  content: "\e9ae";
}
.ni-swap-horizontal-circle-fill:before {
  content: "\e9af";
}
.ni-swap-horizontal-circle:before {
  content: "\e9b0";
}
.ni-warning-circle-fill:before {
  content: "\e9b1";
}
.ni-blueprint-72:before {
  content: "\e97c";
}
.ni-calendar-today:before {
  content: "\e977";
}
.ni-cancel:before {
  content: "\e978";
}
.ni-check-circle:before {
  content: "\e979";
}
.ni-place:before {
  content: "\e97a";
}
.ni-tick-circle:before {
  content: "\e97b";
}
.ni-minus:before {
  content: "\e975";
}
.ni-plus:before {
  content: "\e976";
}
.ni-cat-accessories:before {
  content: "\e965";
}
.ni-cat-acoustic-solutions:before {
  content: "\e966";
}
.ni-cat-cable-management:before {
  content: "\e967";
}
.ni-cat-chairs:before {
  content: "\e968";
}
.ni-cat-custom-made-solutions:before {
  content: "\e969";
}
.ni-cat-desks:before {
  content: "\e96a";
}
.ni-cat-executive-furniture:before {
  content: "\e96b";
}
.ni-cat-lounge-and-soft-furniture:before {
  content: "\e96c";
}
.ni-cat-marketing-materials:before {
  content: "\e96d";
}
.ni-cat-meeting:before {
  content: "\e96e";
}
.ni-cat-real-leather:before {
  content: "\e96f";
}
.ni-cat-reception-furniture:before {
  content: "\e970";
}
.ni-cat-storage:before {
  content: "\e971";
}
.ni-cat-surface-materials:before {
  content: "\e972";
}
.ni-cat-textures:before {
  content: "\e973";
}
.ni-cat-upholstery-materials:before {
  content: "\e974";
}
.ni-add-circle-outline-1:before {
  content: "\e956";
}
.ni-add-circle:before {
  content: "\e957";
}
.ni-apps:before {
  content: "\e958";
}
.ni-double-arrow:before {
  content: "\e959";
}
.ni-export:before {
  content: "\e95a";
}
.ni-file-add:before {
  content: "\e95b";
}
.ni-info:before {
  content: "\e95c";
}
.ni-list-alt:before {
  content: "\e95d";
}
.ni-list:before {
  content: "\e95e";
}
.ni-remove-circle-outline:before {
  content: "\e95f";
}
.ni-remove-circle:before {
  content: "\e960";
}
.ni-send:before {
  content: "\e961";
}
.ni-sticky-note:before {
  content: "\e962";
}
.ni-view-column:before {
  content: "\e963";
}
.ni-view-stream:before {
  content: "\e964";
}
.ni-add-circle-outline:before {
  content: "\e900";
}
.ni-add-lg:before {
  content: "\e901";
}
.ni-add-task:before {
  content: "\e902";
}
.ni-archive:before {
  content: "\e903";
}
.ni-arrow-back:before {
  content: "\e904";
}
.ni-arrow-downward:before {
  content: "\e905";
}
.ni-arrow-drop-down:before {
  content: "\e906";
}
.ni-arrow-drop-left:before {
  content: "\e907";
}
.ni-arrow-drop-right:before {
  content: "\e908";
}
.ni-arrow-drop-up:before {
  content: "\e909";
}
.ni-arrow-forward:before {
  content: "\e90a";
}
.ni-arrow-upward:before {
  content: "\e90b";
}
.ni-article:before {
  content: "\e90c";
}
.ni-assignment:before {
  content: "\e90d";
}
.ni-attachment-rotate:before {
  content: "\e90e";
}
.ni-blur-circular:before {
  content: "\e90f";
}
.ni-chat-square-plain:before {
  content: "\e910";
}
.ni-check:before {
  content: "\e911";
}
.ni-chevron-left:before {
  content: "\e912";
}
.ni-chevron-right:before {
  content: "\e913";
}
.ni-clipboard:before {
  content: "\e914";
}
.ni-clock:before {
  content: "\e915";
}
.ni-close:before {
  content: "\e916";
}
.ni-content-copy:before {
  content: "\e917";
}
.ni-content-cut:before {
  content: "\e918";
}
.ni-content-paste:before {
  content: "\e919";
}
.ni-copy:before {
  content: "\e91a";
}
.ni-copyright:before {
  content: "\e91b";
}
.ni-create:before {
  content: "\e91c";
}
.ni-customer-service:before {
  content: "\e91d";
}
.ni-cut:before {
  content: "\e91e";
}
.ni-delete-file:before {
  content: "\e91f";
}
.ni-delete:before {
  content: "\e920";
}
.ni-discount:before {
  content: "\e921";
}
.ni-document:before {
  content: "\e922";
}
.ni-download-bracket:before {
  content: "\e923";
}
.ni-download-done:before {
  content: "\e924";
}
.ni-download:before {
  content: "\e925";
}
.ni-east:before {
  content: "\e926";
}
.ni-edit-text:before {
  content: "\e927";
}
.ni-expand-less:before {
  content: "\e928";
}
.ni-expand-more:before {
  content: "\e929";
}
.ni-faq:before {
  content: "\e92a";
}
.ni-file-alert:before {
  content: "\e92b";
}
.ni-file-blank:before {
  content: "\e92c";
}
.ni-file-down:before {
  content: "\e92d";
}
.ni-file-tick:before {
  content: "\e92e";
}
.ni-file-up:before {
  content: "\e92f";
}
.ni-file:before {
  content: "\e930";
}
.ni-flaky:before {
  content: "\e931";
}
.ni-folder-add:before {
  content: "\e932";
}
.ni-folder-remove:before {
  content: "\e933";
}
.ni-folder:before {
  content: "\e934";
}
.ni-home-work:before {
  content: "\e935";
}
.ni-logout-bracket:before {
  content: "\e936";
}
.ni-low-priority:before {
  content: "\e937";
}
.ni-mail:before {
  content: "\e938";
}
.ni-more-horiz:before {
  content: "\e939";
}
.ni-north:before {
  content: "\e93a";
}
.ni-notification-bell:before {
  content: "\e93b";
}
.ni-office:before {
  content: "\e93c";
}
.ni-open-in-new:before {
  content: "\e93d";
}
.ni-paper-plane:before {
  content: "\e93e";
}
.ni-rename:before {
  content: "\e93f";
}
.ni-reorder:before {
  content: "\e940";
}
.ni-restore:before {
  content: "\e941";
}
.ni-save-alt:before {
  content: "\e942";
}
.ni-search:before {
  content: "\e943";
}
.ni-settings:before {
  content: "\e944";
}
.ni-shipping-box:before {
  content: "\e945";
}
.ni-shipping:before {
  content: "\e946";
}
.ni-south:before {
  content: "\e947";
}
.ni-space-box:before {
  content: "\e948";
}
.ni-switch-bottom:before {
  content: "\e949";
}
.ni-switch-top:before {
  content: "\e94a";
}
.ni-text-block:before {
  content: "\e94b";
}
.ni-time:before {
  content: "\e94c";
}
.ni-toggle-solid:before {
  content: "\e94d";
}
.ni-toggle:before {
  content: "\e94e";
}
.ni-upload:before {
  content: "\e94f";
}
.ni-view-list-block:before {
  content: "\e950";
}
.ni-view-list:before {
  content: "\e951";
}
.ni-view-module:before {
  content: "\e952";
}
.ni-view-squares:before {
  content: "\e953";
}
.ni-warning-circle:before {
  content: "\e954";
}
.ni-west:before {
  content: "\e955";
}
