@mixin alert-variant($background, $border, $color) {
  --#{$prefix}alert-color: #{$color};
  --#{$prefix}alert-bg: #{$background};
  --#{$prefix}alert-border-color: #{$border};

  @if $enable-gradients {
    background-image: var(--#{$prefix}gradient);
  }

  .alert-link {
    color: shade-color($color, 20%);
  }
}

.alert {
  &-primary {
    @include alert-variant($primary-500, $primary-500, $black);
  }

  &-success {
    @include alert-variant($success-500, $success-500, $white);
  }

  &-warning {
    @include alert-variant($warning-500, $warning-500, $white);
  }

  &-danger {
    @include alert-variant($danger-500, $danger-500, $white);
  }

  &-light {
    @include alert-variant($gray-400, $gray-400, $gray-900);
  }

  &-dark {
    @include alert-variant($black, $black, $white);
  }

  &-info {
    @include alert-variant($gray-700, $gray-700, $white);
  }
}
