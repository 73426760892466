@import '@angular/cdk/overlay-prebuilt.css';
@import './assets/sass/colors';
@import './assets/sass/fonts';
@import './assets/sass/font-family';
@import './assets/sass/form';
@import './assets/sass/modal';
@import './assets/sass/custom';
@import 'bootstrap/scss/bootstrap';
@import './assets/sass/alert';
@import './assets/sass/buttons';
@import './assets/sass/datepicker';

body {
  @include font-family-primary;
  background-color: $gray-200;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-secondary;
}

h1,
.h1 {
  line-height: 2.5rem;
  font-size: 2rem;
}

h2,
.h2 {
  line-height: 2rem;
  font-size: 1.5rem;
}

h3,
.h3 {
  line-height: 1.5rem;
  font-size: 1.25rem;
}

h4,
.h4 {
  line-height: 1.25rem;
  font-size: 1rem;
}

a:not([href]):not([class]) {
  color: $primary;

  &:hover {
    cursor: pointer;
    color: $primary-700;
  }
}

.custom-modal > .modal-dialog {
  max-width: 1024px;
  width: 80%;
  margin-top: 0;

  .modal-content {
    border-radius: 0;
    border: 0;
  }
}

.no-outline:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: $table-border-color;
}

button {
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    cursor: default;

    i {
      cursor: default;
    }
  }
}

i {
  cursor: pointer;
}

.modal-backdrop {
  opacity: 0 !important;
}

.modal {
  background: $modal-bg-color !important;

  &-sm {
    width: 700px !important;
    max-width: 700px !important;
  }
}

.messages-modal {
  background-color: $messages-modal-bg-color;

  .modal-dialog {
    position: fixed;
    margin: auto;
    max-width: 375px;
    min-width: 375px;
    height: 100%;
    right: 0;

    @include media-breakpoint-down('sm') {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
    }
  }

  .modal-content {
    height: 100%;
    overflow-y: auto;
    background-color: $gray-200;
    border-radius: 0;
    box-shadow: 0 10px 30px $messages-modal-content-box-shadow-color;
    border: none;
  }

  .modal-body {
    padding: 0;
  }
}

.text {
  &--grey {
    color: $gray-600;

    &--darker {
      color: $gray-600;
    }
  }

  &--black {
    color: $color-black-light;
  }

  &--white {
    color: $white;
  }

  &--green {
    color: $color-green;
  }
}

.highlight {
  background: $search-highlight;
  pointer-events: none;
}

.auto-grow {
  flex-basis: 0;
  flex-grow: 1;
}

.w-50px {
  width: 50px !important;
}

.w-60px {
  width: 60px !important;
}

.w-70px {
  width: 70px !important;
}

.w-100px {
  width: 100px !important;
}

.w-150px {
  width: 150px !important;
}

.w-200px {
  min-width: 200px;
  width: 200px !important;
}

.w-250px {
  min-width: 250px;
  width: 250px !important;
}

.w-350px {
  min-width: 350px;
  width: 350px !important;
}

.w-500px {
  min-width: 500px;
  width: 500px !important;
}

/** for IE11 and Firefox **/
input {
  &::-ms-clear {
    display: none;
  }
}

select {
  &::-ms-expand {
    display: none;
  }
}

a.custom-link {
  color: $color-green !important;
}

.separated-items {
  display: inline-block;
  word-break: break-all;

  &--slash {
    span:not([style*='display: none']) + span:not([style*='display: none']):before {
      content: ' / ';
    }
  }

  &--line {
    span:not([style*='display: none']) + span:not([style*='display: none']):before {
      content: ' | ';
    }
  }
}

.selected-count {
  position: absolute;
  top: -15px;
  right: -15px;
  background: $color-green;
  color: $white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-break--all {
  word-break: break-all;
}

.text-break--word {
  word-break: break-word;
}

.disable-scroll {
  overflow-y: hidden;
}

svg {
  width: 100%;
  height: 100%;
}

.flex-break {
  flex-basis: 22px;
  width: 0;
}

/** for IE11 and Firefox end **/

.userback-button-container {
  @include media-breakpoint-down('sm') {
    display: none !important;
  }
}

.max-height-dynamic {
  #app & {
    max-height: calc(100vh - #{$navbar-height});
  }

  #app.has-maintenance-banner & {
    max-height: calc(100vh - #{$navbar-height} - #{$maintenance-banner-height});
  }
}
