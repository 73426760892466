@import './src/assets/sass/_colors';

ngb-pagination {
  .pagination {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .page {
      &-item {
        &.active .page-link {
          background-color: $primary !important;
          color: $white !important;
        }

        .page-link:hover {
          background-color: $gray;
          color: $black;
        }

        &:first-child .page-link,
        &:last-child .page-link {
          background: none;
          color: $primary;
          padding: 0;
          margin: 0;
          min-width: 1.5rem;
        }

        &:first-child .page-link {
          margin-right: 2rem;
        }

        &:last-child .page-link {
          margin-left: 2rem;
        }

        &.disabled .page-link {
          background: none;
          color: $gray-600;
        }
      }

      &-link {
        margin: 0 0.125rem;
        padding: 0 0.75rem;
        min-width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $black;
        border-radius: 1.875rem;
      }
    }
  }
}

