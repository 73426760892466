$white: #fff !default;
$black: #000 !default;

$gray-100: #F9F9F9 !default;
$gray-200: #F2F2F2 !default; // Light Background Secondary
$gray-300: #E5E5E5 !default;
$gray-400: #D8D8D8 !default;
$gray-500: #9C9C9C !default;
$gray-600: #848484 !default;
$gray-700: #646464 !default;
$gray-800: #2C2C2E !default;
$gray-900: #1C1C1E !default;

$primary-100: #E6F0FF;
$primary-200: #CCE1FF;
$primary-700: #0D6EFF !default;
$primary-600: #3788FF !default;
$primary-500: #4A93FF !default;

$success-100: #C6F2E2 !default;
$success-200: #A5ECD3 !default;
$success-700: #0C9966 !default;
$success-600: #0DB578 !default;
$success-500: #06C882 !default;

$warning-700: #D13705 !default;
$warning-600: #F44006 !default;
$warning-500: #FA5722 !default;
$warning-400: #FFBF84 !default;
$warning-300: #FFD4AC !default;
$warning-200: #F9EAD6 !default;
$warning-100: #FFF7ED !default;

$danger-700: #BC1F1F !default;
$danger-600: #DD3737 !default;
$danger-500: #EB5757 !default;

// Legacy coloring
$color-black-light: #050505;
$color-black-lighter: #313231; // dark-grey
$color-black-lightest: #4A4A4A; // dark-grey

$color-green-darker: #82A800;
$color-green-dark: #95C000;
$color-green: #9DCA00;
$color-green-lighter: #9CCC02;

$color-yellow-dark: #FECC00;
$color-yellow: #FFFF00;

// --- extracted stuff with non stock colors, WIP ---
// styles
$white-bg-color: rgba(255, 255, 255, 0.5);
$modal-bg-color: rgba(0, 0, 0, 0.501);
$messages-modal-bg-color: rgba(246, 246, 246, 0.4);
$messages-modal-content-box-shadow-color: rgba(0, 0, 0, 0.05);
$dialog-modal-dialog-modal-content-box-shadow-color: rgba(0, 0, 0, 0.101);
$navbar-link-grey-color: #9d9d9d;

// dropdowns
$dropdown-menu-box-shadow-color: rgba(0, 0, 0, 0.2);
$form-drop-down-background-color: #f4f4f4;

// order-articles-list
$drag-handle-icon-fill-color: #dadada;
$active-mode-sale-bg-color: #F5E8D8;
$expired-old-non-standard-bg-color: rgba(250, 87, 34, 0.1);
$expired-old-non-standard-hover-bg-color: rgb(255, 238, 233);

// table
$table-container-address-hover-bg-color: rgba(233, 233, 233, 0.2);
$table-input-select-text-color: #313231;
$table-footer-bg-color: #fcfcfc;
$table-border-color: #e8e8e8;

// tooltip
$host-ng-tooltip-black-tooltip-content-box-shadow-color: rgba(0, 0, 0, 0.05);

// customers list
$customers-list-item-background-color: #f8f8f8;
$customers-list-item-border-color: #eeeeee;

// document template
$document-template-image-border-color: #e3e3e3;

// Datepicker
$datepicker-nav-arrow-color: #979797;
$datepicker-shadow: 0 8px 14px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2), 0 2px 0 $gray-700;

// News
$news-image-placeholder: $gray-400 !default;

// Material popover
$material-placeholder: $gray-200 !default;

// Third navbar
$third-navbar-bg-color: #f5f5f5;
$third-navbar-child-link: #333333;
$third-navbar-child-wrapper: #898989;

// Base colors definitions
$search-highlight: #FFD62F;

$graphite-light-grey-t: rgba(216, 216, 216, 0.5) !default;

// Theme color default definitions
$primary: $primary-500 !default;
$secondary: $white !default;
$success: $success-500 !default;
$info: $gray-700 !default;
$warning: $warning-500 !default;
$danger: $danger-500 !default;
$light: $gray-300 !default;
$dark: $black !default;
$gray: $gray-500 !default;

// Bootstrap update fixes
$card-bg: $white;
$dropdown-bg: $white;
$modal-content-bg: $white;
$table-bg: transparent;
