// bootstrap theming
@import 'colors';

// This table defines the theme colors (variant names)
$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'gray': $gray,
    'white': $white,
  ),
  $theme-colors
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1472px,
);
$grid-gutter-width: 2rem !default;

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 100%,
  xxl: 1408px,
);

$enable-negative-margins: true;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$border-width: 1px !default;

// border-radius
$border-radius-sm: 2px;
$border-radius: 0.25rem;
$border-radius-lg: .5rem;
$border-radius-xl: 1rem;
$border-radius-2xl: 2rem;
$border-radius-pill: 50rem;


$body-color: $gray-600 !default;
$headings-color: $black !default;

$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.25 !default;
$h4-font-size: $font-size-base * 1 !default;
$h5-font-size: $font-size-base * 0.85 !default;
$h6-font-size: $font-size-base * 0.75 !default;

$headings-line-height: 1.5 !default;

$modal-inner-padding: 3rem !default;
$modal-header-padding-y: 1.5rem !default;
$modal-header-padding-x: 1.5rem !default;

$font-size-lg: $font-size-base * 1.125 !default;
$font-size-sm: $font-size-base * 0.875 !default;

$body-bg: $gray-400;

// Links
$link-color: $primary !default;
$link-hover-color: $primary-700 !default;
$link-decoration: none !default;
$link-hover-decoration: none !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 10% !default; // variable doesn't exist in bootstrap anymore


// Buttons
$btn-border-width: 0 !default;
$btn-border-radius: $border-radius-sm;
$btn-border-radius-lg: $border-radius-sm !default;
$btn-border-radius-sm: $border-radius-sm !default;

$btn-padding-y: 0.5rem !default;
$btn-padding-x: 1rem !default;
$btn-font-size: 1rem !default;
$btn-line-height: 1.5rem !default;

$btn-padding-y-sm: 0.25rem !default;
$btn-padding-x-sm: 1rem !default;
$btn-font-size-sm: 0.875rem !default;

$btn-padding-y-lg: 1rem !default;
$btn-padding-x-lg: 2rem !default;
$btn-font-size-lg: 1rem !default;

$component-active-color: $white !default;
$component-active-bg: $primary !default;

// $input-bg: $white !default;
$input-color: $black !default;
$input-bg: $gray-200 !default;
$input-disabled-bg: $gray-200 !default;
$input-border-width: 0.125rem !default;
$input-border-color: $gray-200 !default;
$input-border-radius: $border-radius-sm;

$input-focus-border-color: $primary !default;
$input-focus-box-shadow: none !default;

$enable-validation-icons: false;
$form-feedback-invalid-color: $danger !default; // this will be used for input borders with invalid state too
$form-feedback-margin-top: 0.5rem !default;
$form-feedback-font-size: 0.875rem !default;
$form-label-color: $gray;

// form check
$form-check-input-bg: transparent;
$form-check-input-width: 1.125rem;
$form-check-padding-start: $form-check-input-width + 0.5rem;
$form-check-margin-bottom: 0 !default;
$form-check-input-border: 2px solid $gray;
$form-check-input-checked-color: $white !default;

$form-check-input-border-radius: $border-radius-sm !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$white}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>");

$form-check-input-indeterminate-color: $form-check-input-checked-color !default;
$form-check-input-indeterminate-bg-color: $component-active-bg !default;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color !default;

// form switch
$form-switch-color: $gray-400;
$form-switch-width: 2rem;
$form-switch-padding-start: $form-switch-width + 0.5rem;

$form-switch-focus-color: $gray-400;

$form-switch-checked-color: $white;

// form select
$form-select-indicator-color: $gray-600;
$form-select-bg-size: 10px 5px;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='5' viewBox='0 0 10 5'><path fill='#{$form-select-indicator-color}' d='M0 0L5 5L10 0H0Z'/></svg>");
$form-select-focus-box-shadow: none !default;

// tab pills
$nav-pills-border-radius: 0 !default;
$nav-pills-link-active-color: $black !default;
$nav-pills-link-active-bg: $white !default;

// nav
$nav-link-padding-y: 1rem !default;

$small-font-size: 1rem * 0.875 !default;

// Cards
$card-cap-bg: $white !default;
$card-spacer-x: 1rem !default;
$card-border-radius: $border-radius-sm;

$modal-content-border-radius: 0 !default;

// Tables
$table-cell-padding-y:        1rem !default;
$table-cell-padding-x:        1rem !default;
$table-cell-padding-y-sm:     0.5rem !default;
$table-cell-padding-x-sm:     0.5rem !default;
$table-color: $black;
$table-hover-bg: $white;
$table-th-font-weight: normal;
$table-border-color: $gray;

// Custom file text
$custom-file-text: (
  en: 'Browse',
  de: 'Durchsuche',
  lt: 'Naršyti',
  ru: 'Просматривать',
  fr: 'Parcourir',
);

// Dropdown
$dropdown-border-radius: $border-radius-sm !default;
$dropdown-border-width: 0 !default;
$dropdown-padding-x: 0 !default;
$dropdown-padding-y: 0 !default;
$dropdown-color: $gray-400 !default;

// Dropdown links
$dropdown-link-color: $gray-600 !default;
$dropdown-link-hover-color: darken($gray-900, 5%) !default;
$dropdown-link-hover-bg: $gray-200  !default;

// Dropdown item
$dropdown-item-padding-y: 0.75rem !default;
$dropdown-item-padding-x: 1rem !default;
$dropdown-link-active-color: $black !default;
$dropdown-link-active-bg: $gray-400 !default;

// Pagination
$pagination-padding-y: 0.5rem !default;
$pagination-padding-x: 0.5rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;

$pagination-font-size: $font-size-sm;
$pagination-color: $link-color !default;
$pagination-bg: transparent !default;
$pagination-border-width: 0 !default;
$pagination-border-color: transparent !default;

// $pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline: 0 !default;

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-200 !default;
$pagination-hover-border-color: $gray-300 !default;

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-300 !default;

$pagination-border-radius-sm: 50% !default;
$pagination-border-radius-lg: 50% !default;

// Alerts
// Define alert colors, border radius, and padding.
$alert-margin-bottom: 1rem !default;
$alert-border-radius: 0 !default;
$alert-link-font-weight: normal !default;

$alert-bg-scale: 0%;
$alert-border-scale: 0%;
$alert-color-scale: 0%;

// Popovers
$popover-bg: $gray-100 !default;
$popover-border-color: $gray-400 !default;
$popover-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12) !default;

$popover-body-color: $black !default;
$popover-body-padding-y: 1rem !default;
$popover-body-padding-x: 1rem !default;

// Progress bar
$progress-height: 2rem;
$progress-font-size: 1rem;
$progress-border-radius: 0.25rem;
$progress-bg: $gray-400;
$progress-bar-color: $white;

// Toast
$toast-spacing: 0.75rem;
$toast-font-size: 1rem;

// z-index
$filterable-list-header-footer-z-index: 12;
$control-area-z-index: 4;
$order-articles-list-header-z-index: 4;
$maintenance-banner-z-index: 4;
$loader-z-index: 11;
$sticky-navbar-z-index: 1001;
$burger-menu-z-index: 1001;
$chat-drag-drop-z-index: 11;

// reserved elements height
$navbar-height: 3.75rem; // 60px
$sub-navbar-height: 3rem; // 48px
$maintenance-banner-height: 3rem; // 48px
