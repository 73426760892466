@import 'src/assets/sass/_custom.scss';

.modal-header {
  justify-content: space-between;
}

@include media-breakpoint-down(sm) {
  .mobile-responsive-modal-footer {
    display: flex;
    flex-direction: column;

    button {
      width: 100% !important;
    }
  }
}
