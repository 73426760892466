/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2022 Colophon Foundry
 *
 * Licenced to Andrius Jurginaitis Narbutas International, UAB 300591314
 */

@font-face {
    font-family: 'visuelt-regular-pro';
    src: url('visuelt-regular-pro.eot');
    src: url('visuelt-regular-pro.eot?#iefix') format('embedded-opentype'),
         url('visuelt-regular-pro.woff2') format('woff2'),
         url('visuelt-regular-pro.woff') format('woff'),
         url('visuelt-regular-pro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
