@import 'colors';

.btn {
  &.disabled,
  &:disabled {
    cursor: not-allowed;
    pointer-events: all;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    cursor: pointer;
  }
  &.no-outline:focus,
  &.btn-link:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

// Custom buttons
@mixin custom-button-colors($standard, $hover, $active, $focus, $disabled) {
  color: $standard !important;

  &:hover {
    color: $hover !important;
  }

  &.active,
  &:active {
    color: $active !important;
  }

  &.focus,
  &:focus {
    color: $focus !important;
  }

  &.disabled,
  &:disabled {
    color: $disabled !important;
  }
}

@mixin custom-button-background($standard, $hover, $active, $focus, $disabled) {
  background-color: $standard !important;

  &:hover {
    background-color: $hover !important;
  }

  &.active,
  &:active {
    background-color: $active !important;
  }

  &.focus:not(.active):not(:active),
  &:focus:not(.active):not(:active) {
    background-color: $focus !important;
  }

  &.disabled,
  &:disabled {
    background-color: $disabled !important;
  }
}

@mixin custom-button-defaults() {
  outline: none !important;
  outline-offset: 0;
  box-shadow: none !important;

  &:focus,
  &.focus {
    outline: 0.125rem solid rgba($primary-500, 0.5) !important;
  }

  &:disabled,
  &.disabled {
    opacity: 0.42 !important;
  }
}

.btn {
  &-primary {
    @include custom-button-defaults;
    @include custom-button-colors($white, $white, $white, $white, $white);
    @include custom-button-background($primary-500, $primary-600, $primary-700, $primary-500, $primary-500);
  }
  &-secondary {
    @include custom-button-defaults;
    @include custom-button-colors($gray-600, $black, $black, $black, $gray-600);
    @include custom-button-background($white, $gray-200, $gray-400, $white, $white);
  }
  &-danger {
    @include custom-button-defaults;
    @include custom-button-colors($white, $white, $white, $white, $white);
    @include custom-button-background($danger-500, $danger-600, $danger-700, $danger-500, $danger-500);
  }
  &-link {
    @include custom-button-defaults;
    @include custom-button-colors($primary-500, $primary-600, $primary-700, $primary-500, $gray-600);
    @include custom-button-background(transparent, transparent, transparent, transparent, transparent);
  }
  &-dark {
    @include custom-button-defaults;
    @include custom-button-colors($gray-200, $white, $gray-300, $gray-200, $gray-400);
    @include custom-button-background($black, $gray-800, $gray-900, $black, $black);
  }
  &-light {
    @include custom-button-defaults;
    @include custom-button-colors($black, $black, $gray-800, $black, $black);
    @include custom-button-background($gray-300, $gray-400, $gray-500, $gray-300, $gray-300);
  }
  &-info {
    @include custom-button-defaults;
    @include custom-button-colors($white, $white, $gray-400, $white, $white);
    @include custom-button-background($gray-600, $gray-700, $gray-800, $gray-600, $gray-600);
  }
  &-inverse {
    @include custom-button-defaults;
    @include custom-button-colors($white, $white, $white, $white, $white);
    @include custom-button-background(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
  }
}
